<template>
  <div>
    <header>
      <input
        type="text"
        class="searchInput"
        v-model="keyword"
        placeholder="搜索科室、医生"
        @keyup.enter="handleSearch($event)"
      />
      <i class="fangdajing2"></i>
    </header>
    <section>
      <div
        class="doctor-item"
        v-for="(item, index) in dortorlist"
        :key="index"
        :index="index"
      >
        <div class="doctor-img">
          <img
            src="@/assets/img/yisheng.png"
            v-if="item.portraitUrl == null || item.portraitUrl == ''"
            class="doctor-img"
            alt=""
            @click="doctorPage(item)"
          />
          <img
            :src="item.portraitUrl"
            v-else
            class="doctor-img"
            alt=""
            @click="doctorPage(item)"
          />
        </div>
        <div class="doctor-info">
          <div class="doctor-base-info">
            <span class="doctor-name" @click="doctorPage(item)">{{
              item.doctorName
            }}</span>
            <span class="doctor-deptName" @click="doctorPage(item)">{{
              item.doctorTitle
            }}</span>
            <span class="collect"> 收藏 </span>
            <i
              class="iconfont icon-xinaixin"
              :class="item.concern == false ? 'disactive' : 'active'"
              @click="toCollect(item)"
            ></i>
            <div class="doctor-dept" @click="doctorPage(item)">
              {{ item.deptName }}
            </div>
          </div>
          <!-- <div class="doctor-tel" @click="doctorPage(item)">
            <div class="fl">
              <img
                src="@/assets/img/guide/dingwei.png"
                alt=""
                class="icon"
              />
              <span >{{item.deptPlace==null?'暂无':item.deptPlace}}</span>
            </div>
            <div class="fr">
              <img
                src="@/assets/img/guide/rili.png"
                alt=""
                class="icon"
              />
              <span style="padding-right: 20px">周三、周六全天</span>
            </div>
            <div class="fl">
                <img
                src="@/assets/img/guide/phone.png"
                alt=""
                class="icon"
              />
              <span>0371-85901690</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="grid_title" v-if="deptlist.length !== 0">临床科室</div>
      <div class="grid-item">
        <van-grid>
          <van-grid-item
            v-for="(item, index) in deptlist"
            :key="index"
            @click="
              $router.push(
                '/deptPage?title=' + item.name + '&code=' + item.code
              )
            "
          >
            <template #text>
              <span class="text">{{ item.name }}</span>
            </template>
            <template #icon>
              <i :class="item.name"></i>
            </template>
          </van-grid-item>
        </van-grid>
      </div>
      <div
        v-if="deptlist.length == 0 && dortorlist.length == 0"
        style="font-size: 0.4rem"
      >
        暂无搜索结果
      </div>
    </section>
  </div>
</template>

<script>
import { Notify } from "vant";
export default {
  name: "searchPage",
  data() {
    return {
      keyword: this.$route.query.keyword,
      currentSort: true,
      dortorlist: [],
      deptlist: [],
    };
  },
  methods: {
    // 搜索框回车搜索
    handleSearch(e) {
      var evt = window.event || e;
      if (evt.keyCode == 13) {
        //回车事件
        this.$router.push({
          path: "/searchPage",
          query: {
            keyword: this.keyword,
          },
        });
      }
      this.getDeptList();
      this.getDortorList();
    },
    async toCollect(item) {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        doctorId: item.doctorCode,
        deptCode: item.deptCode,
      };
      if (!item.concern) {
        // 收藏
        await this.$http.post("/api/favorite/add", postData);
        Notify({
          message: "已收藏",
          color: "#fff",
          background: "#00D48B",
        });
      } else {
        // 取消收藏
        await this.$http.put("/api/favorite/cancle", postData);
        Notify({
          message: "取消收藏",
          color: "#fff",
          background: "#C6C6C6",
        });
      }
      item.concern = !item.concern;
      this.getDeptList();
    },
    getDeptList() {
      let postData = {
        key: this.$route.query.keyword,
      };
      this.$http.get("/api/dept", postData).then((res) => {
        if (res.status == 200) {
          this.deptlist = res.data;
        }
      });
    },
    getDortorList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        key: this.$route.query.keyword,
      };
      this.$http
        .get("/api/dept/doctor", postData)
        .then((res) => {
          if (res.status == 200) {
            this.dortorlist = res.data;
          }
        })
        .then(() => {
          this.getDeptList();
        });
    },
    doctorPage(item) {
      sessionStorage.setItem("currentDoctor", JSON.stringify(item));
      this.$router.push({
        path: "/doctorPage",
      });
    },
  },
  mounted() {
    this.getDortorList();
  },
};
</script>

<style scoped>
@import url(//at.alicdn.com/t/font_2783444_ka1zwfgai2j.css);
.icon {
  width: 1em !important;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
}
.icon-xinaixin {
  float: right;
  font-size: 0.45rem !important;
  padding-top: 2px;
  padding-right: 3px;
}
.active {
  color: #00d48b !important;
}
.disactive {
  color: #c6c6c6 !important;
}
header {
  font-size: 0.4rem;
  position: relative;
}
.searchInput {
  height: 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  text-align: left;
  text-indent: 1.2rem;
  width: 9rem;
  background-color: #fff;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.text {
  font-size: 0.3rem;
}
.grid_title {
  font-size: 0.5rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.grid_title:before {
  content: "";
  display: inline-block;
  width: 0.1rem;
  height: 0.5rem;
  margin: 0 10px;
  border-radius: 2px;
  background-color: #00d48b;
  position: relative;
  top: 3px;
}
.grid-item {
  width: 9rem;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

/deep/.van-grid-item__content {
  height: auto;
}
.van-search__content {
  background-color: white;
  border-radius: 1rem;
}
/deep/.van-hairline:after {
  border: none;
}
.doctor-item {
  width: 9rem;
  height: 2.6rem;
  background-color: #fff;
  margin: auto;
  margin-top: 0.2rem;
  border-radius: 15px;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
.doctor-img {
  width: 2rem;
  float: left;
}
.doctor-img img {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 5px;
  margin-top: 10px;
  border-radius: 50%;
}
.doctor-info {
  width: 7rem;
  height: inherit;
  float: left;
}
.doctor-base-info {
  width: 7rem;
  height: 1.4rem;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
  font-size: 0.4rem;
  margin-top: 20px;
}
.doctor-dept {
  text-align: left;
  margin-top: 0.1rem;
}
.doctor-name {
  color: #333333;
  padding-right: 15px;
  font-weight: bold;
}
.doctor-deptName {
  color: #333333;
}
.collect {
  float: right;
  font-size: 0.4rem;
  padding-right: 15px;
  color: #a4a5ab;
}
.doctor-tel {
  font-size: 0.4rem;
  text-align: left;
}
.doctor-tel div {
  margin-top: 0.1rem;
}
.icon {
  width: 0.35rem;
  height: 0.35rem;
  padding-right: 3px;
}
</style>
